import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { _WizardForm } from '../../model/variables.model';

@Injectable({
  providedIn: 'root'
})
export class WizardFormService {
  private userNumber = new BehaviorSubject<string>('');
  number = this.userNumber.asObservable();

  private hasOrders = new BehaviorSubject<boolean>(false);
  userOrders = this.hasOrders.asObservable();

  constructor(private _HttpClient: HttpClient) { }

  /* check-status */
  currentStep(mobileNumber: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/check-current-step`, mobileNumber);
  }
  /*
*
*
*//* get-all-orders */
  getAllOrders(mobileNumber: any, pageNumber: number = 1, maxCount: number = 10): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/get-all-maintenance-orders?per_page=${maxCount}&page=${pageNumber}`, mobileNumber);
  }
  /*
  *
  *
  *//* first-status (register) */
  register(userData: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/register`, userData)
  }
  /*
  *
  *
  *//* second-status (call) */
  remainingSeconds(mobileNumber: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/get-remaining-time`, mobileNumber);
  }

  callStatus(mobileNumber: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/get-calling-status`, mobileNumber);
  }

  resetCall(mobileNumber: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/reset-calling-status`, mobileNumber);
  }

  orderStatus(mobileNumber: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/check-order-status`, mobileNumber);
  }
  /*
  *
  *
  *//* third-status (payment) */
  paymentDetails(orderId: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/payment-screen-details`, orderId);
  }

  checkOut(orderDetails: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/create-checkout-id`, orderDetails);
  }

  paymentStatus(orderDetails: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/get-payment-status`, orderDetails);
  }

  paymentSuccess(transactionStatus: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/payment-success`, transactionStatus);
  }

  applyCoupon(couponDetails: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/apply-coupon`, couponDetails);
  }
  /*
  *
  *
  *//* fourth-status (payment) */
  paymentReceipt(orderId: any): Observable<any> {
    return this._HttpClient.post(`${_WizardForm}/payment-receipt`, orderId);
  }
  /*
   *
   *
   *//* users-orders-observable */
  updateUsersOrders(doesHave: boolean) {
    this.hasOrders.next(doesHave);
  }

  updateUserNumber(number: string) {
    this.userNumber.next(number);
  }


}
