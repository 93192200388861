import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { initFlowbite } from 'flowbite';
import { PrimeNGConfig } from 'primeng/api';


import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from "./shared/components/footer/footer.component";
import { LocalizationService } from './shared/services/localization/localization.service';

LocalizationService

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {


  constructor(private _primengConfig: PrimeNGConfig, private _LocalizationService:LocalizationService) { }

  ngOnInit(): void {
    this._LocalizationService.ltr.subscribe((isLtr) => {
      document.body.style.direction = isLtr ? 'ltr' : 'rtl';
    });

    initFlowbite();
    this.primeNgLocal();
  }



  @HostListener('window:load', ['$event'])
  onLoad() { }
  @HostListener('window:resize', ['$event'])
  onResize() { }

  primeNgLocal() {
    this._primengConfig.setTranslation({
      dayNames: ["الأحد", "الإثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"],
      dayNamesShort: ["أحد", "إثن", "ثلاث", "أربع", "خميس", "جمع", "سبت"],  // Short names
      dayNamesMin: ["الأحد", "الإثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"],  // Minimal names
      monthNames: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
      monthNamesShort: ["ينا", "فبر", "مار", "أبر", "ماي", "يون", "يول", "أغس", "سبت", "أكت", "نوف", "ديس"],
      today: "اليوم",
      clear: "مسح",
      dateFormat: "dd/mm/yy",
      weekHeader: "أسبوع"
    });
  }

  openWhatsApp(): void {
    const phoneNumber = '966509152104';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, '_blank');
  }

}
