import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { LocalizationService } from '../../services/localization/localization.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  lang!: string;

  constructor(
    private _LocalizationService: LocalizationService,
  ) { };
  ngOnInit(): void {
    this._LocalizationService.currentLang.subscribe(value => this.lang = value);
  }
}
