<!-- header-section-1 -->
<section class="header-section-1" style="direction: rtl !important;">
    <nav class="bg-[#1B1D29] dark:bg-gray-900 py-4">
        <div class="md:container md:mx-auto flex flex-wrap md:flex-nowrap items-center justify-between px-4">
            <a [routerLink]="'/' + lang" class="flex items-center space-x-3 rtl:space-x-reverse">
                <img src="../../../../assets/lazerLogo.png" class="w-20 md:w-28" alt="Laser logo in arabic" />
            </a>
            <button data-collapse-toggle="navbar-default" type="button"
                class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-default" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 1h15M1 7h15M1 13h15" />
                </svg>
            </button>

            <div class="hidden md:flex md:justify-between md:items-center md:w-full" id="navbar-default">
                <ul class="font-medium inline-flex flex-col md:flex-row md:justify-end p-4 md:p-0 w-full">
                    <!-- <li class="my-1 mx-1">
                        <a routerLinkActive="active" routerLink="/book-service" class="block py-2 px-3 text-white text-xl" aria-current="page">احجز
                            موعدك للصيانة
                        </a>
                    </li> -->

                    @if(userHasOrders) {
                    <!-- ['/order-wizard/all-orders', this.userNumber, this.lang] -->
                    <li class="my-1 mx-1">
                        <a routerLinkActive="active"
                            [routerLink]="['/order-wizard/all-orders', this.userNumber, this.lang]"
                            [queryParams]="{ page_no: 1 }" class="block py-2 px-3 text-white text-xl"
                            aria-current="page">
                            {{'header:orderPage' | translate}}
                        </a>
                    </li>
                    }

                    <li class="my-1 mx-3">
                        <a (click)="changeLang(nextLang)"
                            class="block py-2 px-5 bg-[#F1F0F5] rounded-3xl text-[#1B1D29] text-xl cursor-pointer"
                            aria-current="page">
                            <i class="pi pi-language"></i>
                            {{languageText}}
                        </a>
                    </li>
                </ul>

                <ul class="font-medium inline-flex flex-col p-4 md:p-0">
                    <li>
                        <ul class="flex justify-center items-center">
                            <li class="flex justify-center items-center y-1 mx-1">
                                <a class="block px-1 text-white text-sm" aria-current="page">
                                    {{ 'contact-us' | translate}}
                                </a>
                                <i class="pi pi-headphones text-white text-2xl px-1"></i>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul class="flex justify-center items-center">
                            <li class="my-1 mx-1">
                                <a href="https://www.instagram.com/thelaserapp?igsh=YjVxZGlrNjV4OXUx" target="_blank"
                                    class="rounded-full py-2 px-3 bg-[#F1F0F5] w-8 h-8 flex justify-center items-center text-xl"
                                    aria-current="page">
                                    <i class="pi pi-instagram text-[#1B1D29]"></i>
                                </a>
                            </li>
                            <li class="my-1 mx-1">
                                <a href="https://www.tiktok.com/@thelaserapp?_t=8pjslt1nSLa&_r=1" target="_blank"
                                    class="rounded-full py-2 px-3 bg-[#F1F0F5] w-8 h-8 flex justify-center items-center text-xl"
                                    aria-current="page">
                                    <i class="pi pi-tiktok text-[#1B1D29]"></i>
                                </a>
                            </li>
                            <li class="my-1 mx-1">
                                <a href="https://www.facebook.com/profile.php?id=61561907561977&mibextid=LQQJ4d"
                                    target="_blank"
                                    class="rounded-full py-2 px-3 bg-[#F1F0F5] w-8 h-8 flex justify-center items-center text-xl"
                                    aria-current="page">
                                    <i class="pi pi-facebook text-[#1B1D29]"></i>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</section>

<!-- loading-screen -->
@if(loading) {
<app-loading-screen></app-loading-screen>
}