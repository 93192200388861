import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizationService } from '../../services/localization/localization.service';
import { WizardFormService } from '../../services/wizard-form/wizard-form.service';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LoadingScreenComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  userNumber!: string;
  userHasOrders!: boolean;

  deviceOS!: string;
  loading: boolean = false;
  isValidToken!: boolean;
  lang!: string;
  nextLang!: string
  languageText!: string
  url!: string;

  appleStoreURL: string = 'https://apps.apple.com/app/%D9%84%D9%8A%D8%B2%D8%B1-lazer/id6478968924';
  googleStoreURL: string = 'https://play.google.com/store/apps/details?id=sa.laser';

  constructor(
    private _Router: Router,
    private _WizardFormService: WizardFormService,
    private _LocalizationService: LocalizationService,
    private _DeviceDetectorService: DeviceDetectorService,
  ) { }

  ngOnInit(): void {
    this.deviceOS = this._DeviceDetectorService.getDeviceInfo().os;
    this._WizardFormService.userOrders.subscribe(val => this.userHasOrders = val);
    this._LocalizationService.addLanguages(['ar', 'en']);
    this._LocalizationService.currentLang.subscribe(val => this.lang = val);
    this._LocalizationService.nextLang.subscribe(val => this.nextLang = val);
    this._LocalizationService.languageText.subscribe(val => this.languageText = val);

    this.url = this._Router.url;
    this.getUserNumber();
    // this.setDefaultLang();
  }

  getUserNumber() {
    let uNumber = localStorage.getItem('_mobile');
    if (uNumber !== null) {
      this.userNumber = uNumber;
      this._WizardFormService.updateUserNumber(uNumber);
    }
    this._WizardFormService.number.subscribe(val => this.userNumber = val);
  }

  storeLink(): void {
    switch (this.deviceOS) {
      case 'Mac OS':
        window.open(this.appleStoreURL, '_blank');
        break;
      case 'iOS':
        window.open(this.appleStoreURL, '_blank');
        break;
      default:
        window.open(this.googleStoreURL, '_blank');
        break;
    }
  }

  changeLang(assingLang: string) {
    this.url = window.location.href
    let shortURL = this.url.split('?')[0];
    let updatedUrl;

    if (shortURL.endsWith('/en')) { updatedUrl = shortURL.slice(0, -3) }
    else if (shortURL.endsWith('/')) { updatedUrl = shortURL.slice(0, -1) }
    else { updatedUrl = shortURL }

    if (this.nextLang === 'en') {
      window.location.href = `${updatedUrl}/${this.nextLang}`
    } else {
      window.location.href = `${updatedUrl}`
    }

    // this._LocalizationService.changeLanguage(assingLang);
    // localStorage.setItem('lang', assingLang);

    // if (assingLang === 'ar') {
    //   this.nextLang = 'en';
    //   this.languageText = 'English'
    // } else if (assingLang === 'en') {
    //   this.nextLang = 'ar';
    //   this.languageText = 'العربية'
    // }
  }

  // setDefaultLang() {
  //   let _lang = localStorage.getItem('lang');

  //   if (_lang) {
  //     this._LocalizationService.setDefaultLanguage(_lang);
  //     if (_lang === 'en') {
  //       this.nextLang = 'ar'
  //       this.languageText = 'العربية'
  //     } else if (_lang === 'ar') {
  //       this.nextLang = 'en'
  //       this.languageText = 'English'
  //     }

  //   } else {
  //     this._LocalizationService.setDefaultLanguage("ar");
  //     this.nextLang = 'en'
  //     this.languageText = 'English'
  //   }
  // }
}
