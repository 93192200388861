import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private isLtr = new BehaviorSubject<boolean>(true);
  ltr = this.isLtr.asObservable();

  private isCurrentLang = new BehaviorSubject<string>('');
  currentLang = this.isCurrentLang.asObservable();

  private isNextLang = new BehaviorSubject<string>('');
  nextLang = this.isNextLang.asObservable();

  private isLanguageText = new BehaviorSubject<string>('');
  languageText = this.isLanguageText.asObservable();

  constructor(private _TranslateService: TranslateService) { }

  addLanguages(langs: string[]) {
    this._TranslateService.addLangs(langs);
  }

  setDefaultLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this._TranslateService.setDefaultLang(lang);
    if (lang === 'en') {
      this.isLtr.next(true);
      this.isNextLang.next('ar');
      this.isCurrentLang.next('en');
      this.isLanguageText.next('العربية');
      document.body.setAttribute("dir", "ltr");
    } else if (lang === 'ar') {
      this.isLtr.next(false);
      this.isNextLang.next('en');
      this.isCurrentLang.next('');
      this.isLanguageText.next('English');
      document.body.setAttribute("dir", "rtl");
    }
  }

  changeLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this._TranslateService.use(lang);
    if (lang === 'en') {
      this.isLtr.next(true);
      this.isNextLang.next('ar');
      this.isCurrentLang.next('en');
      this.isLanguageText.next('العربية');
      document.body.setAttribute("dir", "ltr");
    } else if (lang === 'ar') {
      this.isLtr.next(false);
      this.isNextLang.next('en');
      this.isCurrentLang.next('');
      this.isLanguageText.next('English');
      document.body.setAttribute("dir", "rtl");
    }
  }

  toastValue(key: string) {
    return this._TranslateService.instant(key);
  }
}
