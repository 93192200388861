import { HttpInterceptorFn } from '@angular/common/http';

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const _token = localStorage.getItem('uToken');
  const _lang = localStorage.getItem('lang');

  let headers = {};

  if (_token !== null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${_token}`,
    };
  }

  if (_lang !== null) {
    headers = {
      ...headers,
      'Accept-Language': _lang,
    };
  } else {
    headers = {
      ...headers,
      'Accept-Language': 'ar',
    };
  }

  const clonedReq = req.clone({ setHeaders: headers })

  return next(clonedReq);

};
