<!-- footer-section-1 -->
<section class="footer-section-1">
    <div class="grid grid-cols-2 md:grid-cols-6 bg-[#1B1D29] py-4">
        <div class="col-span-2 hidden md:flex justify-center items-center">
            <a [routerLink]="'/' + lang" class="text-base text-white font-[600] mx-3">{{"footerHome" | translate}}</a>
            <a [routerLink]="'/contact-us/' + lang" class="text-base text-white font-[600] mx-3">
                {{"footerContact"| translate}}
            </a>
        </div>
        <div class="col-span-2">
            <div class="flex flex-col justify-center items-center">
                <a [routerLink]="'/' + lang" class="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="../../../../assets/lazerLogo.png" class="w-24" alt="Laser logo in arabic" />
                </a>
                <p class="text-white mt-4">{{'footerCR' | translate}}</p>
            </div>
            <div class="flex md:hidden flex-col justify-center items-center">
                <a [routerLink]="'/' + lang" class="text-base text-white font-[600] my-2">{{"footerHome" |
                    translate}}</a>
                <a [routerLink]="'/contact-us/' + lang" class="text-base text-white font-[600] my-2">
                    {{"footerContact"| translate}}
                </a>
                <a [routerLink]="'/privacy-policy/' + lang" class="text-base text-white font-[600] my-2">
                    {{"footerPrivacy" | translate}}
                </a>
                <a [routerLink]="'/terms-conditions/' + lang" class="text-base text-white font-[600] my-2">
                    {{"footerTerms" | translate}}
                </a>
            </div>
        </div>
        <div class="col-span-2 hidden md:flex justify-center items-center">
            <a [routerLink]="'/terms-conditions/' + lang" class="text-base text-white font-[600] mx-3">
                {{"footerTerms" | translate}}
            </a>
            <a [routerLink]="'/privacy-policy/' + lang" class="text-base text-white font-[600] mx-3">
                {{"footerPrivacy" | translate}}
            </a>
        </div>
    </div>
</section>